import React from "react";

export default function icon({
  icon,
  width = 15,
  height = 15,
  alt = "",
  className,
}) {
  return (
    <img
      src={icon}
      alt={alt}
      width={width}
      height={height}
      className={className}
    />
  );
}
