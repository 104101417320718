import * as React from "react";
import Layout from "../components/layout";
import Link from "../components/common/link";

const StyleguidPage = () => {
  return (
    <Layout>
      <h1>Heading 1</h1>
      <h2>Heading 2</h2>
      <h3>Heading 3</h3>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent sit
        amet leo ornare mauris venenatis dictum. Sed ullamcorper purus ac erat
        dapibus, sed rhoncus odio aliquam. Interdum et malesuada fames ac ante
        ipsum primis in faucibus. Nulla a imperdiet tortor. Sed orci risus,
        ultricies ac risus sed, sollicitudin tempor libero. Cras nec nisl quam.
        Nulla eget commodo tortor, quis hendrerit nibh. Nunc malesuada imperdiet
        ex nec luctus. Fusce dignissim, felis a tincidunt euismod, felis augue
        lacinia ante, auctor euismod urna magna at nisl. Praesent id mauris
        nulla.
      </p>
      <p>
        Maecenas sit amet libero vulputate, ultrices elit at, vulputate enim.
        Suspendisse congue faucibus lectus eu posuere. Quisque odio dolor,
        molestie vel pellentesque sed, pharetra vitae purus. Mauris finibus dui
        eget dui consequat, eget gravida nunc convallis. Vestibulum varius at
        tellus sed tempus. Nullam convallis lorem enim, at gravida turpis
        elementum eu. Proin sed scelerisque nisl, id faucibus tellus. In eu
        nulla metus. Donec congue mi eget ligula accumsan rhoncus.
      </p>
      <Link to="styleguide">Read More</Link>
    </Layout>
  );
};

export default StyleguidPage;
