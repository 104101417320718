import React from "react";
import { Link } from "gatsby";

import Icon from "./icon";
import ArrowIcon from "../../images/icons/arrow.svg";
import * as linkStyles from "./link.module.css";

function AppLink({ to, children }) {
  return (
    <Link to={to} className={linkStyles.link}>
      {children}
      <Icon icon={ArrowIcon} />
    </Link>
  );
}

export default AppLink;
